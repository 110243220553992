import React, { useEffect, useState } from 'react'
import useFetch from '../../hooks/useFetch';
import DataTable from 'react-data-table-component';
import CreateCompany from '../actions/CreateCompany';
import { MdOutlineAddBusiness } from 'react-icons/md';
import { HiOutlineReceiptTax } from "react-icons/hi";
import CreateComission from '../actions/CreateComission';


const Business = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { data, refetch } = useFetch(`${apiUrl}/info/company`);
    const [records, setRecords] = useState([]);
    const [createCompany, setCreateCompany] = useState(false);
    const [createComission, setCreateComission] = useState(false);

    console.log(records)

    useEffect(() => {
        data && setRecords(data.filter(item => item !== null));
    }, [data]);


    const columns = [
        {
            name: "ID",
            selector: (row: any) => row.id,
            sortable: true,

        },
        {
            name: "Compañías",
            selector: (row: any) => row.name,
            sortable: true,

        },
        {
            name: "NIF",
            selector: (row: any) => row.nif,
            sortable: true,
        },
        {
            name: "Comisión %",
            selector: (row: any) => row.commission,
            sortable: true,
        },
        {
            name: "Tipo",
            selector: (row: any) => row.type,
            sortable: true,
        },
        {
            name: "Cuenta asoc.",
            selector: (row: any) => row.userName,
            sortable: true,
        },
    ];


    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    return (
        <div className="table-bill">
            <div className="table-container">
                <span className='table-name'>Empresas colaboradoras</span>
                <div className='rtable-addon'>
                    <button className='reportgen' onClick={() => setCreateCompany(true)}><span><MdOutlineAddBusiness style={{ fontSize: '2rem' }} /></span> </button>
                    <button className='reportgen' onClick={() => setCreateComission(true)}><span><HiOutlineReceiptTax style={{ fontSize: '2rem' }} /></span> </button>
                </div>
            </div>

            <DataTable
                columns={columns}
                data={records}
                pagination paginationComponentOptions={paginationComponentOptions}
                noDataComponent="No hay ningún dato que mostrar"
            />
            {createCompany && (
                <CreateCompany
                    editModal={createCompany}
                    setEditModal={setCreateCompany}
                    refetch={refetch} />
            )}

            {createComission && (
                <CreateComission
                    editModal={createComission}
                    setEditModal={setCreateComission}
                    refetch={refetch} />
            )}

        </div>
    )
}

export default Business