import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import "./App.scss";
import Bill from './pages/Bill';
import Rectify from './pages/Rectify';
import Users from './pages/Users';
import Request from './pages/Request';
import Profile from './pages/Profile';
import Summary from './pages/Summary';
import Login from './pages/Login';
import Callcenter from './pages/Callcenter';
import TriagePage from './pages/TriagePage';
import { ToastContainer } from 'react-toastify';
import Zones from './pages/Zones';
import { User } from './context/Context';
import MyProfile from './pages/MyProfile';
import Dashboard from './pages/Dashboard';
import Transactions from './pages/Transactions';
import Hospital from './pages/Hospital';
import 'flag-icons/css/flag-icons.min.css';
import Company from './pages/Company';

const PrivateRoutes = () => {
  const token = localStorage.getItem('token');
  return (
    token ? <Outlet /> : <Navigate to='/login' />
  );
}

const RedirectToHomeIfAuthenticated = () => {
  const token = localStorage.getItem('token');
  return token ? <Navigate to="/" /> : <Login />;
}

const App = () => {
  const { currentUser } = User();
  const roleId = currentUser?.roleId;

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path='/login' element={<RedirectToHomeIfAuthenticated />} />
        <Route element={<PrivateRoutes />}>
          {roleId === 1 && (
            <>
              {/* Routes for admin */}
              <Route path='/' element={<TriagePage />} />
              <Route path='/facturas' element={<Bill />} />
              <Route path='/rectificativas' element={<Rectify />} />
              <Route path='/solicitudes' element={<Request />} />
              <Route path='/usuarios' element={<Users />} />
              <Route path='/perfil/:guid' element={<Profile />} />
              <Route path='/miperfil/:guid' element={<MyProfile />} />
              <Route path='/callcenter' element={<Callcenter />} />
              <Route path='/resumen' element={<Summary />} />
              <Route path='/zonas' element={<Zones />} />
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/transacciones' element={<Transactions />} />
              <Route path='/hospital' element={<Hospital />} />
              <Route path='/empresas' element={<Company />} />
              <Route path="*" element={<Navigate to={"/"} />} />
            </>
          )}
          {roleId === 4 && (
            <>
              {/* Routes for role 4 */}
              <Route path='/' element={<Summary />} />
              <Route path='/facturas' element={<Bill />} />
              <Route path='/rectificativas' element={<Rectify />} />
              <Route path="*" element={<Navigate to={"/"} />} />
              <Route path='/transacciones' element={<Transactions />} />
              <Route path='/miperfil/:guid' element={<MyProfile />} />
            </>
          )}
          {roleId === 5 && (
            <>
              {/* Routes for role 5 */}
              <Route path='/' element={<TriagePage />} />
              <Route path='/callcenter' element={<Callcenter />} />
              <Route path="*" element={<Navigate to={"/"} />} />
              <Route path='/miperfil/:guid' element={<MyProfile />} />
            </>
          )}
          {roleId === 6 && (
            <>
              {/* Routes for role 6 */}
              {/* <Route path='/' element={<Zones />} /> */}
              <Route path='/' element={<TriagePage />} />
              <Route path="*" element={<Navigate to={"/"} />} />
              <Route path='/miperfil/:guid' element={<MyProfile />} />
            </>
          )}
          {roleId === 8 && (
            <>
              {/* Routes for role 6 */}
              <Route path='/' element={<Hospital />} />
              <Route path="*" element={<Navigate to={"/"} />} />
              <Route path='/miperfil/:guid' element={<MyProfile />} />
            </>
          )}
        </Route>
        {/* Catch-all route for undefined routes */}
        <Route path="*" element={<Navigate to={localStorage.getItem('token') ? "/" : "/login"} />} />
      </Routes>
    </>
  )
}

export default App