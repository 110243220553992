import React, { useEffect, useState } from 'react';
import Modal from '../../utilities/Modal';
import './CreateCompany.scss';
import { toast } from 'react-toastify';
import useFetch from '../../hooks/useFetch';
import Select from 'react-select';


const CreateCompany = ({ editModal, setEditModal, refetch }: any) => {
    const [companyData, setCompanyData] = useState({
        nif: '',
        name: '',
        type: '',
        address: '',
        cp: '',
        commission: '',
        phone: '',
        // PaymentDays: 0,
        email: '',
        userId: '',
        dateStart: '',
        dateEnd: '',
        location: '',
        zone: '',
        action_zones: [] as number[],
    });
    const [filteredUsers, setFilteredUsers] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;

    const { data: users } = useFetch(`${apiUrl}/admin/users`);
    const { data: zones } = useFetch(`${apiUrl}/admin/zone`);

    useEffect(() => {
        if (users) {
            const validRoles = ["Aseguradora", "Medical Center", "Hospital", "Hotel"];
            const filtered = users.filter((user: any) => validRoles.includes(user.roleId));
            setFilteredUsers(filtered);
        }
    }, [users]);

    const handleChange = (e: any) => {
        setCompanyData({
            ...companyData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found.');
                return;
            }
            const response = await fetch(`${apiUrl}/company/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify({
                    name: companyData.name,
                    type: parseInt(companyData.type),
                    address: companyData.address,
                    commission: parseInt(companyData.commission),
                    phone: "+34" + companyData.phone,
                    email: companyData.email,
                    userId: parseInt(companyData.userId),
                    location: companyData.location,
                    zone: companyData.zone,
                    nif: companyData.nif,
                    cp: companyData.cp,
                    dateStart: new Date(companyData.dateStart),
                    dateEnd: new Date(companyData.dateEnd),
                    action_zones: companyData.action_zones
                }),

            });

            if (!response.ok) {
                toast.error('Error en crear la empresa');
                return;
            }

            setEditModal(false);
            toast.success("Empresa creada correctamente");
            refetch();

        } catch (error) {
            console.error('Error:', error);
        }
    };
    const zoneOptions = zones && zones.map((zone: any) => ({
        value: zone.id,
        label: zone.zone
    }));
    const handleZoneChange = (selectedOptions: any) => {
        const selectedZoneIds = selectedOptions.map((option: any) => option.value);  // Extract values (zone IDs)
        setCompanyData({ ...companyData, action_zones: selectedZoneIds });
    };


    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className='company-form'>
                <h2>Crear Compañía</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group-company">
                        <label>NIF</label>
                        <input type="text" name="nif" value={companyData.nif} onChange={handleChange} required />
                    </div>
                    <div className="form-group-company">
                        <label>Nombre</label>
                        <input type="text" name="name" value={companyData.name} onChange={handleChange} required />
                    </div>
                    <div className="form-group-company">
                        <label>Tipo</label>
                        <select name="type" value={companyData.type} onChange={handleChange} required>
                            <option value="" disabled>Selecciona el tipo</option>
                            <option value="1">Seguro</option>
                            <option value="2">Hotel</option>
                            <option value="3">Clinica</option>
                            <option value="4">Hospital</option>
                            <option value="5">Transporte</option>
                        </select>
                    </div>
                    <div className="form-group-company">
                        <label>Dirección</label>
                        <input type="text" name="address" value={companyData.address} onChange={handleChange} required />
                    </div>
                    <div className="form-group-company">
                        <label>Ubicación física</label>
                        <input type="text" placeholder='P.ej: Parque Empresarial' name="location" value={companyData.location} onChange={handleChange} required />
                    </div>
                    <div className="form-group-company">
                        <label>Municipio</label>
                        <input type="text" name="zone" value={companyData.zone} onChange={handleChange} required />
                    </div>
                    <div className="form-group-company">
                        <label>Código Postal (CP)</label>
                        <input type="number" name="cp" value={companyData.cp} onChange={handleChange} required />
                    </div>
                    <div className="form-group-company">
                        <label>Comisión (%)</label>
                        <input type="number" name="commission" value={companyData.commission} onChange={handleChange} />
                    </div>
                    <div className="form-group-company">
                        <label>Teléfono</label>
                        <input type="text" name="phone" value={companyData.phone} onChange={handleChange} required />
                    </div>
                    <div className="form-group-company">
                        <label>Correo</label>
                        <input type="email" name="email" value={companyData.email} onChange={handleChange} required />
                    </div>
                    <div className="form-group-company">
                        <label>Fecha de inicio (contrato)</label>
                        <input type="date" name="dateStart" value={companyData.dateStart} onChange={handleChange} required />
                    </div>
                    <div className="form-group-company">
                        <label>Fecha fin (contrato)</label>
                        <input type="date" name="dateEnd" value={companyData.dateEnd} onChange={handleChange} required />
                    </div>

                    <div className="form-group-company">
                        <label>Asignar Usuario</label>
                        <select name="userId" value={companyData.userId} onChange={handleChange} required>
                            <option value="" disabled>Selecciona un usuario</option>
                            {filteredUsers.map((user: any) => (
                                <option key={user.userId} value={user.userId}>
                                    {user.fullname}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group-company">
                        <label>Zonas de Operación</label>
                        <Select
                            name="action_zones"
                            value={zoneOptions.filter(option => companyData.action_zones.includes(option.value))} // Pre-select existing zones
                            onChange={(selectedOptions) => handleZoneChange(selectedOptions)}
                            options={zoneOptions}
                            isMulti
                            className="basic-multi-select"
                            classNamePrefix="select"
                            menuPlacement="auto"
                        />
                    </div>
                    <div className="button-group-company">
                        <button type="button" className="cancel-btn" onClick={() => setEditModal(false)}>Cancelar</button>
                        <button type="submit" className="submit-btn">Enviar</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default CreateCompany




