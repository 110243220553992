import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../utilities/Modal';
import "./CreateUser.scss";
import PhoneInput from 'react-phone-input-2';
import { LiaTimesSolid } from 'react-icons/lia';
import { FaCheck } from 'react-icons/fa';
import DatePicker, { registerLocale } from "react-datepicker";
import { es } from 'date-fns/locale/es';
import spanish from 'react-phone-input-2/lang/es.json';
import { toast } from 'react-toastify';
import { LANGUAGES } from '../../data/languages';
import useFetch from '../../hooks/useFetch';
import Select from 'react-select';
registerLocale('es', es);

interface Company {
    id: number;
    name: string;
}

const CreateUser = ({ editModal, setEditModal, refetch }: any) => {
    // post endpoint 
    const [selectedRole, setSelectedRole] = useState("");
    const [pngFile, setPngFile] = useState<string | null>();
    const [companies, setCompanies] = useState<Company[]>([]);

    const fileRef = useRef<any>(null);
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        gender: '',
        email: '',
        birthday: '',
        selectidcard: '',
        documentid: '',
        membership: '',
        phone: '',
        language: '',
        companyId: '',
        secondaryLanguages: [] as string[]
    });

    const [errors, setErrors] = useState({
        name: '',
        surname: '',
        email: '',
        documentid: '',
        membership: '',
        phone: ''
    });

    const [validity, setValidity] = useState({
        name: false,
        surname: false,
        email: false,
        documentid: false,
        membership: false,
        phone: false
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { id, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [id]: value
        }));
        validateInput(id, value, id === 'documentid' ? formData.selectidcard : undefined);
    };


    const validateInput = (id: string, value: string, docType = formData.selectidcard) => {
        let error = '';
        let isValid = false;

        switch (id) {
            case 'name':
                if (!/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/.test(value)) {
                    error = 'Nombre no válido.';
                } else {
                    isValid = true;
                }
                break;
            case 'surname':
                if (!/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/.test(value)) {
                    error = 'Apellidos no válidos';
                } else {
                    isValid = true;
                }
                break;
            case 'email':
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                    error = 'Correo electrónico no válido';
                } else {
                    isValid = true;
                }
                break;
            case 'documentid':
                if (docType === '[D]') {
                    if (!/^\d{8}[A-Za-z]$/.test(value)) {
                        error = 'DNI no válido';
                    } else {
                        isValid = true;
                    }
                } else if (docType === '[N]') {
                    if (!/^[XYZ]\d{7,8}[A-Za-z]$/.test(value)) {
                        error = 'NIE no válido';
                    } else {
                        isValid = true;
                    }
                } else if (docType === '[P]') {
                    if (!/^[A-Za-z0-9]{6,9}$/.test(value)) {
                        error = 'Pasaporte no válido';
                    } else {
                        isValid = true;
                    }
                } else {
                    error = 'Tipo de documento no seleccionado';
                }
                break;

            case 'selectidcard':
                isValid = value !== '';
                error = isValid ? '' : 'Tipo de documento no seleccionado';
                break;

            case 'membership':
                if (!/^\d{9}$/.test(value)) {
                    error = 'Número de colegiado no válido';
                } else {
                    isValid = true;
                }
                break;
            case 'phone':
                if (!/^\d{11}$/.test(value)) {
                    error = 'Número de teléfono no válido';
                } else {
                    isValid = true;
                }
                break;
            default:
                break;
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            [id]: error
        }));

        setValidity(prevValidity => ({
            ...prevValidity,
            [id]: isValid
        }));
    };

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        fileRef.current.click();

    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result as string;
                setPngFile(base64String);
            };
            reader.readAsDataURL(file);
        }
    };
    const apiUrl = process.env.REACT_APP_API_URL;
    const [showCompanySelect, setShowCompanySelect] = useState(false);
    const { data: company } = useFetch(`${apiUrl}/options/company `);
    useEffect(() => {
        company && setCompanies(company);
    }, [company]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const isDoctor = selectedRole === '2';

        //  No validar el campo 'membership' si el rol no es médico
        const filteredErrors = isDoctor ? errors : { ...errors, membership: '' };
        const filteredValidity = isDoctor ? validity : { ...validity, membership: true };

        const hasError = Object.values(filteredErrors).some((error) => error !== '');
        const isFormValid = Object.values(filteredValidity).every((valid) => valid === true);

        // Verificar que el formulario es valido y no hay errores
        if (hasError || !isFormValid) {
            toast.error("Por favor, corrija los errores en el formulario antes de continuar.");
            return;
        }
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No hay token guardado.');
                return;
            }
            const response = await fetch(`${apiUrl}/admin/users/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify(
                    {
                        email: formData.email,
                        roleId: parseInt(selectedRole),
                        name: formData.name,
                        surname: formData.surname,
                        genre: formData.gender,
                        birthday: formData.birthday,
                        idcard: `${formData.selectidcard}-${formData.documentid}`,
                        professionalID: formData.membership,
                        phone: `+${formData.phone}`,
                        signature: pngFile ? pngFile : null,
                        companyId: formData.companyId ? parseInt(formData.companyId) : null,
                        languages: formData.secondaryLanguages.length > 0 ? formData.secondaryLanguages : null,
                    }
                ),
            });
            if (response.status === 400) {
                toast.error("El usuario ya existe. Compruebe el número de teléfono, el correo electrónico y la identidad");
                return;
            }
            if (response.ok) {
                setEditModal(false); // Cierra el modal despues de enviar
                refetch();
                toast.success("El usuario se ha creado correctamente")
            }

        } catch (error) {
            console.error('Error:', error);
        }
    }
    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className="medic-container">
                <div className="form-title">
                    <p>Crea un usuario</p>
                    <button onClick={() => setEditModal(false)} className='close-modal'>
                        <LiaTimesSolid />
                    </button>
                </div>

                <div className='selectrole-user'>
                    <label htmlFor="selectstatus">Selecciona el rol:</label>
                    <select name="selectstatus" id="selectstatus" value={selectedRole} onChange={(e: any) => setSelectedRole(e.target.value)}>
                        <option value="" disabled></option>
                        <option value="2">Medico</option>
                        <option value="3">Paciente</option>
                        <option value="4">Contable</option>
                        <option value="5">Call Center</option>
                        <option value="6">Centro Médico</option>
                        <option value="7">Hotel</option>
                        <option value="8">Hospital</option>
                        <option value="9">Aseguradora</option>
                    </select>
                </div>

                {selectedRole && (
                    <div className='formform'>
                        <form onSubmit={handleSubmit}>
                            <div className="userform_details">
                                <div className="userform_input">
                                    <label htmlFor="name">Nombre Completo</label>
                                    <div className='userform_input_combined'>
                                        <input
                                            type="text"
                                            id="name"
                                            placeholder="Nombre"
                                            required
                                            value={formData.name}
                                            onChange={handleInputChange}
                                            className={errors.name ? 'invalid' : validity.name ? 'valid' : ''}
                                        />
                                        <input
                                            type="text"
                                            id="surname"
                                            placeholder="Apellidos"
                                            required
                                            value={formData.surname}
                                            onChange={handleInputChange}
                                            className={errors.surname ? 'invalid' : validity.surname ? 'valid' : ''}
                                        />
                                    </div>
                                    {errors.name && <span className="error">{errors.name}</span>}
                                    {errors.surname && <span className="error"> {errors.surname}</span>}
                                </div>
                                <div className="gender">
                                    <span className="gender_title">Género:</span>
                                    <input type="radio" name="gender" id="radio_1" value="Male" onChange={(e: any) => setFormData({ ...formData, gender: e.target.value })} />
                                    <input type="radio" name="gender" id="radio_2" value="Female" onChange={(e: any) => setFormData({ ...formData, gender: e.target.value })} />
                                    <input type="radio" name="gender" id="radio_3" value="Other" onChange={(e: any) => setFormData({ ...formData, gender: e.target.value })} />
                                    <div className="category">
                                        <label htmlFor="radio_1">
                                            <span className="dot one" />
                                            <span>Masculino</span>
                                        </label>
                                        <label htmlFor="radio_2">
                                            <span className="dot two" />
                                            <span>Femenino</span>
                                        </label>
                                        {/* <label htmlFor="radio_3">
                                            <span className="dot three" />
                                            <span>Otro</span>
                                        </label> */}
                                    </div>
                                </div>


                                <div className="userform_input">
                                    <label htmlFor="email">Correo electrónico</label>
                                    <input
                                        type="email"
                                        id="email"
                                        placeholder="ejemplo@dominio.com"
                                        required
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        className={errors.email ? 'invalid' : validity.email ? 'valid' : ''}
                                    />
                                    {errors.email && <span className="error">{errors.email}</span>}
                                </div>
                                <div className="userform_input">
                                    <label htmlFor="document">Documento de Identidad</label>
                                    <div className='userform_input_combined'>
                                        <select name="selectidcard" id="selectidcard" value={formData.selectidcard} onChange={handleInputChange}>
                                            <option value="" disabled>TIPO</option>
                                            <option value="[D]">DNI</option>
                                            <option value="[N]">NIE</option>
                                            <option value="[P]">PASAPORTE</option>
                                        </select>
                                        <input
                                            type="text"
                                            id="documentid"
                                            placeholder="12345678Z"
                                            value={formData.documentid}
                                            onChange={handleInputChange}
                                            required
                                            className={errors.documentid ? 'invalid' : validity.documentid ? 'valid' : ''}
                                        />
                                    </div>
                                    {errors.documentid && <span className="error">{errors.documentid}</span>}
                                </div>

                                <div className='userform_input'>
                                    <label htmlFor="birth">Fecha de nacimiento</label>
                                    <DatePicker
                                        wrapperClassName="datepicker"
                                        selected={formData.birthday ? new Date(formData.birthday) : null}
                                        onChange={(date: Date | null) => setFormData({ ...formData, birthday: date ? date.toISOString() : '' })}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="dd/mm/aaaa"
                                        isClearable
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={100}
                                        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 16))}
                                        popperPlacement="top-start"
                                        locale="es"

                                    />
                                </div>
                                <div className="userform_input">
                                    <label htmlFor="phone">Número de Teléfono</label>
                                    <PhoneInput
                                        buttonStyle={{
                                            border: "none",
                                        }}
                                        inputStyle={{
                                            width: "100%",
                                            height: "45px",
                                            border: "none",
                                            outline: "none",
                                            backgroundColor: "#f6f8fa",
                                            fontSize: "16px",
                                            borderRadius: "5px",
                                            transition: "all 120ms ease-out 0s",
                                            boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)"
                                        }}
                                        containerClass={errors.phone ? 'invalid' : validity.phone ? 'valid' : ''}
                                        localization={spanish}
                                        regions={'europe'}
                                        country={'es'}
                                        value={formData.phone}
                                        onChange={(phone) => {
                                            setFormData({ ...formData, phone });
                                            validateInput('phone', phone);
                                        }}
                                    />

                                    {errors.phone && <span className="error">{errors.phone}</span>}
                                </div>

                                {(selectedRole === '3' || selectedRole === '2') && (
                                    <div className="userform_languages" style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                                        {/* Primary Language Selection */}
                                        <div className="primary-language" style={{ flex: 1 }}>
                                            <label htmlFor="language-select">Idioma Principal</label>
                                            <select
                                                id="language-select"
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                    const selectedLanguage = e.target.value;
                                                    setFormData((prevForm) => ({
                                                        ...prevForm,
                                                        language: selectedLanguage,
                                                        secondaryLanguages: prevForm.secondaryLanguages.filter(lang => lang !== selectedLanguage) // Remove from secondaryLanguages if selected
                                                    }));
                                                }}
                                                value={formData.language}
                                                className='update-form-input'
                                            >
                                                <option value="" disabled>Selecciona un idioma</option>
                                                {LANGUAGES.filter((language) => !formData.secondaryLanguages.includes(language.value))
                                                    .map((language) => (
                                                        <option key={language.value} value={language.value}>
                                                            {language.label}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>

                                        {/* Secondary Languages Selection */}
                                        {selectedRole === '2' &&
                                            <div className="secondary-languages" style={{ flex: 1 }}>
                                                <label htmlFor="secondary-language-select">Idiomas Secundarios</label>
                                                <Select
                                                    id="secondary-language-select"
                                                    isMulti
                                                    options={LANGUAGES.filter((language) => language.value !== formData.language) // Filter out primary language
                                                        .map(language => ({
                                                            value: language.value,
                                                            label: language.label
                                                        }))
                                                    }
                                                    value={LANGUAGES.filter(option => formData.secondaryLanguages.includes(option.value)) // Pre-select the selected values
                                                        .map(language => ({
                                                            value: language.value,
                                                            label: language.label
                                                        }))
                                                    }
                                                    onChange={(selectedOptions: any) => {
                                                        const selectedValues = selectedOptions.map((option: any) => option.value);
                                                        setFormData((prevForm) => ({
                                                            ...prevForm,
                                                            secondaryLanguages: selectedValues
                                                        }));
                                                    }}
                                                    className='react-select-container'
                                                    classNamePrefix='react-select'
                                                    styles={{
                                                        control: base => ({
                                                            ...base,
                                                            height: 45,
                                                            maxWidth: 320,
                                                            backgroundColor: '#f6f8fa'
                                                        }),
                                                        valueContainer: (css: any) => ({ ...css, "flexWrap": "nowrap", "whiteSpace": "nowrap", overflow: "hidden", 'textOverflow': 'ellipsis' })
                                                    }}
                                                    menuPlacement="auto"
                                                />
                                            </div>
                                        }
                                    </div>
                                )}

                                {selectedRole === "2" && (
                                    <>
                                        <div className="userform_input">
                                            <label htmlFor="policycard">Número de Colegiado</label>
                                            <input
                                                type="text"
                                                id="membership"
                                                placeholder="XXYYZZZZZ"
                                                required
                                                value={formData.membership}
                                                onChange={handleInputChange}
                                                className={errors.membership ? 'invalid' : validity.membership ? 'valid' : ''}
                                            />
                                            {errors.membership && <span className="error">{errors.membership}</span>}
                                        </div>
                                        <div className="userform_input_file">
                                            <label htmlFor="sign">Añadir firma:</label>
                                            <button
                                                onClick={handleClick}
                                                className="addSignature">
                                                Subir un archivo
                                            </button>
                                            {pngFile && <span className='filename'>seleccionado<FaCheck className='filename-icon' /></span>}
                                            <input
                                                onChange={handleFileChange}
                                                // onChange={(e: any) => setPngFile(e.target.files[0])}
                                                type="file" accept=".png" hidden ref={fileRef}
                                            />
                                        </div>
                                    </>
                                )}

                                <div className="userform_input">
                                    {selectedRole === '3' ?
                                        <label className='linkCompany' htmlFor="company">Procedencia &#40;opcional&#41;</label>
                                        : <label className='linkCompany' htmlFor="company">Vincular a una empresa</label>}
                                    {!showCompanySelect ? (
                                        <span className="link-text" onClick={() => setShowCompanySelect(true)}>
                                            Asignar empresa
                                        </span>
                                    ) : (
                                        <select
                                            id="company"
                                            value={formData.companyId || ''}
                                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                                setFormData({ ...formData, companyId: e.target.value })
                                            }
                                            className="update-form-input"
                                        >
                                            <option value="" disabled>
                                                Selecciona una empresa
                                            </option>
                                            {companies && companies.map((company) => (
                                                <option key={company.id} value={company.id}>
                                                    {company.name}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>

                            </div>
                            <div className="reg_btn_user">
                                <input type="submit" value="Crear" />
                            </div>
                        </form>
                    </div>
                )}

            </div>
        </Modal >
    )
}

export default CreateUser
