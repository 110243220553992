import React, { useEffect, useState } from 'react'
import "./Usertables.scss";
import DataTable from 'react-data-table-component';
import { MdEditNote, MdOutlinePersonOutline, MdOutlineRestoreFromTrash } from 'react-icons/md';
import Delete from '../actions/Delete';
import Update from '../actions/Update';
import { useNavigate } from 'react-router-dom';
import CreateUser from '../actions/CreateUser';
import { BsSearch } from 'react-icons/bs';
import useFetch from '../../hooks/useFetch';
import moment from 'moment';
import Button from '../../utilities/Button';

const Usertables = () => {

    const [rowData, setRowData] = useState([]);
    const [rowEdit, setRowEdit] = useState(false);
    const [rowDelete, setRowDelete] = useState(false);
    const [createUser, setCreateUser] = useState(false);
    const [activeButton, setActiveButton] = useState<string>('medico');
    const [searchValue, setSearchValue] = useState<string>('');


    const apiUrl = process.env.REACT_APP_API_URL;
    const { data: users, refetch } = useFetch(`${apiUrl}/admin/users`);
    const [records, setRecords] = useState<any[]>([]); // New state for filtered data

    console.log(records)

    useEffect(() => {
        if (users) {
            if (activeButton === 'todos') {
                setRecords(users.sort((a: any, b: any) => new Date(b.createAt).getTime() - new Date(a.createAt).getTime()));
            } else {
                setRecords(users.filter((row: any) => row.roleId.toLowerCase() === activeButton)
                    .sort((a: any, b: any) => new Date(b.createAt).getTime() - new Date(a.createAt).getTime()));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users]);

    const navigate = useNavigate();
    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, (char) => char.toUpperCase())
            .replace(/\b([À-ÿ])/g, (match) => match.toLowerCase());
    }
    const columns = [
        {
            name: "USUARIO",
            selector: (row: any) => capitalizeWords(row.fullname),
            sortable: true,
        },
        {
            name: activeButton === 'todos' ? "ROL" : "IDENTIFICACIÓN",
            selector: (row: any) => activeButton === 'todos' ? row.roleId : row.documentid,
            sortable: true,
        },
        {
            name: activeButton === 'paciente' || activeButton === 'medico' ? "ZONA" : "TELÉFONO",
            selector: (row: any) => activeButton === 'paciente' || activeButton === 'medico' ? row.zone || '-' : row.phone,
            sortable: true,
        },
        {
            name: activeButton === 'paciente' || activeButton === 'medico' ? "ESTADO" : "REGISTRADO EN",
            selector: (row: any) => activeButton === 'paciente' || activeButton === 'medico' ? row.status || '-' : moment(row.createAt).utc().format('DD/MM/YYYY HH:mm'),
            sortable: true,
        },
        {
            name: "Acciones",
            cell: (row: any) => (
                <div className='action-dropdown'>
                    <Button click={() => navigate(`/perfil/${row.guid}`, { state: { id: row.userId, role: row.roleId } })} title="Ver Perfil" icon={<MdOutlinePersonOutline />} disabled={false} />
                    <Button click={() => handleEdit(row)} title="Editar" icon={<MdEditNote />} disabled={false} />
                    <Button click={() => handleDelete(row)} title="Eliminar" icon={<MdOutlineRestoreFromTrash />} disabled={false} />

                </div>
            )
        },

    ];
    const handleEdit = (row: any) => {
        setRowData(row);
        setRowEdit(true);
        // setShowDrop(false);
    }

    const handleDelete = (row: any) => {
        setRowData(row);
        setRowDelete(true);
        // setShowDrop(false);
    }

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const handleFilter = (event: any) => {
        const value = event.target.value;
        setSearchValue(value);

        const sortedUsers = users.sort((a: any, b: any) => new Date(b.createAt).getTime() - new Date(a.createAt).getTime());

        filterData(value, sortedUsers, activeButton);
    }


    const handleButtonClick = (buttonName: string) => {
        setActiveButton(buttonName);

        if (!users) {
            setRecords([]);
            return;
        }

        const sortedUsers = users.sort((a: any, b: any) => new Date(b.createAt).getTime() - new Date(a.createAt).getTime());

        filterData(searchValue, sortedUsers, buttonName);
    }

    const filterData = (searchText: string, usersList: any[], role: string) => {
        let newData;

        if (role === 'todos') {
            newData = usersList.filter((row: any) => {
                return (row.fullname + " " + row.zone + " " + row.status + " " + row.documentid).toLowerCase().includes(searchText.toLowerCase());
            });
        } else {
            newData = usersList
                .filter((row: any) => row.roleId.toLowerCase() === role.toLowerCase())
                .filter((row: any) => (row.fullname + " " + row.zone + " " + row.status + " " + row.documentid).toLowerCase().includes(searchText.toLowerCase()));
        }

        newData && setRecords(newData);
    }

    return (
        <div className="table-bill">
            <div className="table-container">
                <span className='table-name'>Usuarios</span>

                <div className='usercardstats'>
                    <div className="stat">
                        <span className="statlabel">Total Usuarios</span>
                        <span className="statnumber">{users.length}</span>
                    </div>
                    <div className="stat">
                        <span className="statlabel">Total Pacientes</span>
                        <span className="statnumber">{users.filter((row: any) => row.roleId.toLowerCase() === 'paciente').length}</span>
                    </div>
                    <div className="stat">
                        <span className="statlabel">Total Médicos</span>
                        <span className="statnumber">{users.filter((row: any) => row.roleId.toLowerCase() === 'medico').length}</span>
                    </div>
                    <div className="stat">
                        <span className="statlabel">Médicos Disponibles</span>
                        <span className="statnumber">{users.filter((row: any) => row.roleId.toLowerCase() === 'medico').filter((row: any) => row.status.toLowerCase() === 'disponible').length}</span>
                    </div>
                    <div className="stat">
                        <span className="statlabel">Médicos Ocupados</span>
                        <span className="statnumber">{users.filter((row: any) => row.roleId.toLowerCase() === 'medico').filter((row: any) => row.status.toLowerCase() === 'ocupado').length}</span>
                    </div>
                </div>

                <div className='create-user'>
                    <span>Roles</span>
                    <button className='new-user' onClick={() => setCreateUser(true)}>Nuevo usuario</button>
                </div>


                <div className='tablefilter'>
                    <div className='demandbuttons'>
                        <button
                            className={activeButton === 'todos' ? 'active' : ''}
                            onClick={() => handleButtonClick('todos')}
                        >
                            Todos
                        </button>
                        <button
                            className={activeButton === 'medico' ? 'active' : ''}
                            onClick={() => handleButtonClick('medico')}
                        >
                            Médicos
                        </button>
                        <button
                            className={activeButton === 'paciente' ? 'active' : ''}
                            onClick={() => handleButtonClick('paciente')}
                        >
                            Pacientes
                        </button>
                        <button
                            className={activeButton === 'contable' ? 'active' : ''}
                            onClick={() => handleButtonClick('contable')}
                        >
                            Contables
                        </button>
                        <button
                            className={activeButton === 'call center' ? 'active' : ''}
                            onClick={() => handleButtonClick('call center')}
                        >
                            CallCenter
                        </button>
                        <button
                            className={activeButton === 'medical center' ? 'active' : ''}
                            onClick={() => handleButtonClick('medical center')}
                        >
                            Centros
                        </button>
                        <button
                            className={activeButton === 'hospital' ? 'active' : ''}
                            onClick={() => handleButtonClick('hospital')}
                        >
                            Hospital
                        </button>

                    </div>

                    <div className='inputfitler-details'>
                        <BsSearch className='zone_search_icon' />
                        <input type="text" placeholder='Buscar por nombre' onChange={handleFilter} />
                    </div>

                </div>
                <DataTable
                    columns={columns}
                    data={records}
                    pagination paginationComponentOptions={paginationComponentOptions}
                />
            </div>

            {rowEdit && (
                <Update
                    rowdata={rowData}
                    editModal={rowEdit}
                    setEditModal={setRowEdit}
                    refetch={refetch} />
            )}
            {rowDelete && (
                <Delete
                    rowdata={rowData}
                    editModal={rowDelete}
                    setEditModal={setRowDelete}
                    refetch={refetch} />
            )}

            {createUser && (
                <CreateUser
                    editModal={createUser}
                    setEditModal={setCreateUser}
                    refetch={refetch} />
            )}
        </div>
    )
}

export default Usertables
