import React, { useEffect, useState } from 'react';
import "./TransferMedic.scss";
import DataTable from 'react-data-table-component';
import Modal from '../../utilities/Modal';
import { LiaTimesSolid } from 'react-icons/lia';
import useFetch from '../../hooks/useFetch';
import { BsSearch } from 'react-icons/bs';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { LANGUAGES } from '../../data/languages';

const AssignMedic = ({ editModal, setEditModal, rowdata }: any) => {
    const [selectedMedic, setSelectedMedic] = useState<any>(null);
    const [resetPaginationToggle] = useState(false);
    const [records, setRecords] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;
    const { data: allmedics } = useFetch(`${apiUrl}/options/findDoctors`);

    useEffect(() => {
        if (allmedics && rowdata.hotelZone) {
            const filteredMedics = allmedics.filter((medic: any) =>
                medic.zoneNames.includes(rowdata.hotelZone)
            );
            setRecords(filteredMedics);
        }
    }, [allmedics, rowdata.hotelZone]);


    const columns = [
        {
            name: '', // radio button column
            cell: (row: any) => (
                <input
                    style={{ width: '20px', height: '20px' }}
                    type="radio"
                    name="medicSelection"
                    value={row}
                    checked={row === selectedMedic}
                    onClick={() => setSelectedMedic(row)}
                />
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px',
        },
        {
            name: "MÉDICO",
            selector: (row: any) => row.fullname,
            sortable: true,
            wrap: true,
        },
        {
            name: 'ESTADO',
            selector: (row: any) => row.status,
            sortable: true,
            cell: (row: any) => (
                <span style={{ color: row.status === 'Disponible' ? '#20A2A0' : '#FF3D32' }}>
                    {row.status}
                </span>
            ),
        },
        // {
        //     name: 'ZONA ACTUAL',
        //     selector: (row: any) => row.zoneNames.length > 0 ? row.zoneNames.join(', ') : "-",
        //     sortable: true,
        //     wrap: true,
        // },
        {
            name: 'IDIOMA',
            selector: (row: any) => row.languageSpoken && row.languageSpoken.languages && row.languageSpoken.languages.length > 0
                ? row.languageSpoken.languages
                    .map((lang: string) => LANGUAGES.find(language => language.value === lang)?.label)
                    .filter(Boolean)
                    .join(', ')
                : '-',
            sortable: true,
            wrap: true,

        },
    ];

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: false,
    };


    const handleRowSelected = (row: any) => {
        setSelectedMedic(row);
    };


    const handleAssign = async () => {
        if (!selectedMedic) {
            toast.error('Por favor, seleccione un médico');
            return;
        }
        if (selectedMedic.status === "Ocupado") {
            toast.error('El médico seleccionado está ocupado');
            return;
        }
        const token = localStorage.getItem('token');

        try {
            if (!token) {
                console.error('No hay token guardado.');
                return;
            }

            const response = await fetch(`${apiUrl}/admin/assigndoctor`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify({
                    doctor_id: selectedMedic.userId,
                    identificator: rowdata.identificator,
                }),
            });
            if (response.ok) {
                setEditModal(false);
                toast.success('Médico asignado con éxito');
                return;
            } else {
                toast.error('Fallo al asignar médico');
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handleFilter = (event: any) => {
        const newData = allmedics.filter((row: any) => {
            return (row.fullname + " " + row.zoneNames.join(', ') + " " + row.status).toLowerCase().includes(event.target.value.toLowerCase());
        })
        setRecords(newData);
    };

    const [selectedStatus, setSelectedStatus] = useState({ value: "Todos", label: "Estado" });
    // const [selectedZone, setSelectedZone] = useState({ value: "Todos", label: "Zona" });

    const statusOptions = [
        { value: "Todos", label: "Estado" },
        { value: "Disponible", label: "Disponible" },
        { value: "En consulta", label: "En consulta" }
    ];

    // const zoneOptions = [
    //     { value: "Todos", label: "Zona" },
    //     { value: "Arguineguin", label: "Arguineguin" },
    //     { value: "Agüimes", label: "Agüimes" },
    //     { value: "Amadores", label: "Amadores" },
    //     { value: "Bahia Feliz", label: "Bahia Feliz" },
    //     { value: "Meloneras", label: "Meloneras" },
    //     { value: "Mogan", label: "Mogan" },
    //     { value: "Maspalomas", label: "Maspalomas" },
    //     { value: "Puerto Rico", label: "Puerto Rico" },
    //     { value: "Playa del Ingles", label: "Playa del Ingles" },
    //     { value: "Patalavaca ", label: "Patalavaca" },
    //     { value: "Salobre", label: "Salobre" },
    //     { value: "San Agustín", label: "San Agustín" },
    //     { value: "San Bartolome de Tirajana", label: "San Bartolome de Tirajana" },
    //     { value: "Sin asignar", label: "Sin asignar" },
    //     { value: "Taurito", label: "Taurito" }
    // ];

    const handleSelectStatus = (selectedOption: any) => {
        setSelectedStatus(selectedOption);
        // setSelectedZone({ value: "Todos", label: "Zona" });

        if (selectedOption.value === "Todos") {
            setRecords(allmedics);
        } else {
            setRecords(allmedics.filter((record: any) => record.status === selectedOption.value));
        }
    };

    // const handleSelectZone = (selectedOption: any) => {
    //     setSelectedZone(selectedOption);
    //     setSelectedStatus({ value: "Todos", label: "Estado" });

    //     if (selectedOption.value === "Todos") {
    //         setRecords(allmedics);
    //     } else {
    //         setRecords(allmedics.filter((record: any) => record.zoneNames.some((zone: string) => zone === selectedOption.value)));
    //     }
    // };

    const customStyles = {
        control: (base: any) => ({
            ...base,
            border: '1px solid #187A78',
            color: '#187A78',
            outline: 'none',
            borderRadius: '8px',
            padding: '2px 10px',
            height: '45px',
            backgroundColor: '#F8F8F8',
        }),
    };

    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className="transfer-doctors">
                <div className='transfer-headers'>
                    <h2>Asignar médico a <span>{rowdata.identificator}</span></h2>
                    <button onClick={() => setEditModal(false)} className='close-modal'>
                        <LiaTimesSolid />
                    </button>
                </div>
                <div className='transferfilter-medics'>
                    <div className='inputfitler-details'>
                        <BsSearch className='zone_search_icon' />
                        <input type="text" placeholder='Buscar...' onChange={handleFilter} />
                    </div>

                    <div className='selectstatus-medics'>
                        <Select
                            name="selectstatus"
                            id="selectstatus"
                            value={selectedStatus}
                            onChange={handleSelectStatus}
                            options={statusOptions}
                            styles={customStyles}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                        {/* <Select
                            name="selectstatus"
                            id="selectstatus2"
                            value={selectedZone}
                            onChange={handleSelectZone}
                            options={zoneOptions}
                            styles={customStyles}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        /> */}
                    </div>
                </div>
                <DataTable
                    columns={columns}
                    data={records}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    selectableRows={false} // disable checkboxes
                    onRowClicked={handleRowSelected}
                    clearSelectedRows={resetPaginationToggle}
                    noDataComponent="No hay ningún dato que mostrar"
                    className="custom-table-transfer"
                />
                <div className="zonedetails-actions">
                    <button className="cancel-button" onClick={() => setEditModal(false)}>Cancelar</button>
                    <button className="transfer-button" onClick={handleAssign}>Asignar</button>
                </div>
            </div>
        </Modal>
    );
};

export default AssignMedic

