import React, { useState } from 'react';
import "./ManualInvoice.scss";
import Modal from '../../utilities/Modal';
import PhoneInput from 'react-phone-input-2';
import spanish from 'react-phone-input-2/lang/es.json';
import { toast } from 'react-toastify';

interface InvoiceItem {
    id: string;
    name: string;
    description: string;
    code: string;
    price: number;
    tax: number;
    total?: number; // Optional if calculated
}

const ManualInvoice = ({ editModal, setEditModal }: any) => {
    const [customerName, setCustomerName] = useState('');
    const [customerSurname, setCustomerSurname] = useState('');
    const [documentId, setDocumentId] = useState('');
    const [phoneNum, setPhoneNum] = useState('');
    const [emailAdd, setEmailAdd] = useState('');
    const [documentType, setDocumentType] = useState('[D]');
    const [paymentMethod, setPaymentMethod] = useState('Tarjeta');
    const [invoiceDate, setInvoiceDate] = useState('');
    const [items, setItems] = useState<InvoiceItem[]>([]);
    const [availableServices, setAvailableServices] = useState<InvoiceItem[]>([
        { id: "1", name: "Forfait 1", description: "Forfait Grupo 1", code: "F1", price: 150, tax: 7 },
        { id: "2", name: "Forfait 2", description: "Forfait grupo 2", code: "F2", price: 300, tax: 7 },
        { id: "3", name: "Forfait 3", description: "Forfait grupo 3", code: "F3", price: 500, tax: 7 },
        { id: "4", name: "Deposito", description: "Ambulancia", code: "AB", price: 650, tax: 0 },
        { id: "5", name: "AMB: UVI", description: "Ambulancia", code: "UV", price: 350, tax: 0 },
        { id: "6", name: "AMB: Sanitarizada", description: "Ambulancia", code: "SA", price: 450, tax: 0 },
        { id: "7", name: "AMB: SVB", description: "Ambulancia", code: "SB", price: 650, tax: 0 }
    ]);
    const [selectedServiceId, setSelectedServiceId] = useState<string | null>(null);
    const apiUrl = process.env.REACT_APP_API_URL;


    const handleAddItem = () => {
        if (selectedServiceId) {
            const selectedService = availableServices.find(service => service.id === selectedServiceId);
            if (selectedService) {
                setItems([...items, { ...selectedService, total: selectedService.price * (1 + selectedService.tax / 100) }]);
                setAvailableServices(availableServices.filter(service => service.id !== selectedServiceId));
                setSelectedServiceId(null); // Clear the selection
            }
        }
    };

    const handleItemChange = (index: number, field: keyof InvoiceItem, value: string | number) => {
        const newItems = [...items];

        // Handle case where the input is cleared
        let newValue: string | number = value;

        if (field === 'price') {
            newValue = value === '' ? '' : Number(value);
        }

        newItems[index] = {
            ...newItems[index],
            [field]: newValue
        };


        if (field === 'price' && newValue !== '') {
            const price = newItems[index].price;
            const tax = newItems[index].tax;
            newItems[index].total = price * (1 + tax / 100);
        }

        setItems(newItems);
    };

    const handleRemoveItem = (index: number) => {
        const removedItem = items[index];
        const newItems = items.filter((_, idx) => idx !== index);
        setItems(newItems);
        setAvailableServices([...availableServices, removedItem]); // Return service to available list
    };


    const totalAmount = items.reduce((acc, item) => acc + (item.total || 0), 0);


    const handleGenerateInvoice = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No hay token guardado.');
                return;
            }
            const response = await fetch(`${apiUrl}/admin/create/singleInvoice/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify({
                    customer: customerName + " " + customerSurname,
                    documentId: `${documentType}-${documentId}`,
                    phoneNum: `+${phoneNum}`,
                    emailAdd: emailAdd,
                    invoiceDate: invoiceDate,
                    selectedServiceId: items.map(service => parseInt(service.id)),
                    paymentMethod: paymentMethod
                }),

            });
            console.log(response)

            if (!response.ok) {
                toast.error('Error en generar la factura');
                return;
            }
            setEditModal(false); // Cierra el modal despues de enviar
            toast.success("Factura creada correctamente");

        } catch (error) {
            console.error('Error:', error);
        }
    };


    const handlePrintInvoice = () => {
        window.print();
    };

    return (
        <Modal modal={editModal} setModal={setEditModal}>
            <div className="create-invoice">
                <h2>Crear Factura</h2>
                <form className="invoice-form">
                    <div className="form-row">
                        <label>Nombre Apellido</label>
                        <div style={{ display: 'flex', flex: '3' }}>
                            <input type="text" name="customerName" placeholder="Nombre" value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
                            <input type="text" name="customerName" placeholder="Apellidos" value={customerSurname} onChange={(e) => setCustomerSurname(e.target.value)} />
                        </div>
                    </div>

                    <div className="form-row">
                        <label>Documento identidad</label>

                        <div style={{ display: 'flex', flex: '3', gap: '10px' }}>
                            <select
                                style={{
                                    padding: '5px',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    flex: '1'
                                }}
                                name="documentType"
                                value={documentType}
                                onChange={(e) => setDocumentType(e.target.value)}
                            >
                                <option value="[D]">DNI</option>
                                <option value="[N]">NIE</option>
                                <option value="[P]">PASS</option>
                            </select>

                            <input
                                type="text"
                                name="documentId"
                                value={documentId}
                                onChange={(e) => setDocumentId(e.target.value)}
                                placeholder="Introduce el número de documento"
                                style={{
                                    flex: '4',
                                    padding: '5px',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px'
                                }}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <label>Correo electrónico</label>
                        <input type="text" name="customerName" value={emailAdd} onChange={(e) => setEmailAdd(e.target.value)} />
                    </div>
                    <div className="form-row">
                        <label>Núm. Teléfono</label>
                        <div style={{ flex: '3' }}>
                            <PhoneInput
                                buttonStyle={{
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f6f8fa",
                                    width: '60px'
                                }}
                                inputStyle={{
                                    width: "100%",
                                    padding: "5px",
                                    paddingLeft: '60px',
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    outline: "none",
                                    fontSize: "16px",
                                    transition: "all 120ms ease-out 0s",
                                    boxShadow: "none",
                                }}

                                localization={spanish}
                                regions={'europe'}
                                country={'es'}
                                value={phoneNum}
                                onChange={(e) => setPhoneNum(e)}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <label>Fecha de operación</label>
                        <input type="date" name="invoiceDate" value={invoiceDate} onChange={(e) => setInvoiceDate(e.target.value)} />
                    </div>

                    <div className="form-row">
                        <label>Método de pago</label>
                        <select
                            style={{ flex: '3', border: '1px solid #ccc', borderRadius: '4px', padding: '5px' }}
                            value={paymentMethod}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        >
                            <option value="" disabled>Seleccionar método de pago</option>
                            <option value="Efectivo">Efectivo</option>
                            <option value="Tarjeta">Tarjeta</option>
                        </select>
                    </div>

                    <table className="invoice-table">
                        <thead>
                            <tr>
                                <th>Servicio</th>
                                <th>Código</th>
                                <th>Precio Unit.</th>
                                <th>Total</th>
                                <th>Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.length === 0 ? (
                                <tr>
                                    <td colSpan={5}>No hay servicios añadidos</td>
                                </tr>
                            ) : (
                                items.map((item, idx) => (
                                    <tr key={idx}>
                                        <td><input type="text" value={item.name} readOnly /></td>
                                        <td><input type="text" value={item.code} readOnly /></td>
                                        <td><input type="number" value={item.price} onChange={(e) => handleItemChange(idx, 'price', e.target.value)} /></td>
                                        <td><input type="number" value={item.total} readOnly /></td>
                                        <td><button type="button" onClick={() => handleRemoveItem(idx)} className="btn-remove">Eliminar</button></td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>

                    <div className="form-row">
                        <select
                            style={{ border: '1px solid' }}
                            value={selectedServiceId || ''}
                            onChange={(e) => setSelectedServiceId(e.target.value)}
                        >
                            <option value="" disabled>Seleccionar servicio</option>
                            {availableServices.map(service => (
                                <option key={service.id} value={service.id}>
                                    {service.name} - {service.code}
                                </option>
                            ))}
                        </select>
                        <button type="button" onClick={handleAddItem} className="btn-add">Añadir Servicios</button>
                    </div>

                    <div className="total-amount">
                        <label>Importe total:</label>
                        <span>{totalAmount}</span>
                    </div>

                    <div className="invoice-buttons">
                        <button type="button" onClick={handleGenerateInvoice} className="btn-generate">Generar Factura</button>
                        <button type="button" onClick={handlePrintInvoice} className="btn-print">Imprimir</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default ManualInvoice
