import React, { useState, useEffect } from 'react';
import useFetch from '../../hooks/useFetch';
import Select from 'react-select';
import Loading from '../loading/Loading';
import "./Triage.scss";
import Derive from '../actions/Derive';
import SuccessTriage from '../actions/SuccessTriage';
import { BsShieldExclamation } from "react-icons/bs";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi2";
import { toast } from 'react-toastify';
import spanish from 'react-phone-input-2/lang/es.json';
import PhoneInput from 'react-phone-input-2';
import DatePicker, { registerLocale } from "react-datepicker";
import { es } from 'date-fns/locale/es';
import ShowInstruction from '../actions/ShowInstruction';
import { IoArrowBackOutline, IoCheckmarkDone } from 'react-icons/io5';
import { MdDoneOutline, MdQuestionMark } from 'react-icons/md';
import { LANGUAGES } from '../../data/languages';
registerLocale('es', es);
interface Option {
    value: string;
    label: string;
}

const Triage: React.FC = () => {
    const [firstValue, setFirstValue] = useState<Option | null>(null);
    const [secondValue, setSecondValue] = useState<Option | null>(null);
    const [thirdValue, setThirdValue] = useState<Option[] | null>([]);
    const [secondOptionsData, setSecondOptionsData] = useState([]);
    const [thirdOptionsData, setThirdOptionsData] = useState([]);
    // const [secondLoading, setSecondLoading] = useState(true);
    // const [thirdLoading, setThirdLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;

    // const [isInsured, setIsInsured] = useState<boolean | null>(null);
    // const [hasPolicy, setHasPolicy] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [instruction, setInstruction] = useState(false);
    const [emergency, setEmergency] = useState<boolean | null>(null);
    const [level0, setLevel0] = useState<boolean | null>(null);
    const [done, setDone] = useState(false);
    const [userExist, setUserExist] = useState<boolean | null>(null);
    const [question, setQuestion] = useState(true);
    const [validUser, setValidUser] = useState<boolean | null>(null);
    const [identificator, setIdentificator] = useState("");
    const [selectedDocument, setSelectedDocument] = useState<string>('');
    const [language, setLanguage] = useState<string>('en');
    const [backButton, setBackButton] = useState(false);
    const [isPhoneInput, setIsPhoneInput] = useState(false); // toggle between document and phone input

    const { data: firstOptionsData, loading: firstLoading } = useFetch(`${apiUrl}/triage/callcenter/system`);
    // const { data: company } = useFetch(`${apiUrl}/options/company`); <--------- PAK DIS SHIT
    const { data: hotelOptionsData } = useFetch(`${apiUrl}/hotels/all`);

    useEffect(() => {
        if (firstValue) {
            const getSymptom = async () => {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No hay token guardado.');
                    return;
                }
                const result = await fetch(`${apiUrl}/triage/callcenter/system/symptom/${firstValue.value}`, {
                    method: "GET",
                    headers: {
                        'Authorization': token,
                    }
                });
                const jsonData = await result.json();
                setSecondOptionsData(jsonData);
                // setSecondLoading(false);
            };

            getSymptom();
        }

        if (secondValue) {
            const getAsymptom = async () => {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No hay token guardado.');
                    return;
                }
                const result = await fetch(`${apiUrl}/triage/callcenter/system/symptom/associated-symptom/${secondValue.value}`, {
                    method: "GET",
                    headers: {
                        'Authorization': token,
                    }
                });
                const jsonData = await result.json();
                setThirdOptionsData(jsonData);
                // setThirdLoading(false);
            };

            getAsymptom();
        }

    }, [apiUrl, firstValue, secondValue]);

    const firstOptions = firstOptionsData
        .map((item: any) => ({
            value: item.id.toString(),
            label: item.system
        }));

    const secondOptions = secondOptionsData
        .map((item: any) => ({
            value: item.id.toString(),
            label: item.symptom
        }));

    const thirdOptions = thirdOptionsData
        .map((item: any) => ({
            value: item.id.toString(),
            label: item.asymp
        }));



    const handleFirstChange = (selectedOption: Option | null) => {
        setFirstValue(selectedOption);
        setSecondValue(null);
        setSecondOptionsData([]);
        setThirdValue(null);
        setThirdOptionsData([]);
    };

    const handleSecondChange = (selectedOption: Option | null) => {
        setSecondValue(selectedOption);
        setThirdValue(null);
        setThirdOptionsData([]);
    };

    const handleThirdChange = (selectedOption: any) => {
        setThirdValue(selectedOption);
    };

    const handleUrgency = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No hay token guardado.');
            return;
        }

        const triageData = {
            systemRes: firstValue ? parseInt(firstValue.value) : null,
            symptomRes: secondValue ? parseInt(secondValue.value) : null,
            asympRes: `[${thirdValue?.map(item => item.value).join(', ')}]` // Para que tenga este formato "[num]"
        };

        const response = await fetch(`${apiUrl}/triage/value`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(triageData)
        });

        const result = await response.json();

        if (result === 1) {
            setEmergency(true);
        } else if (result.error === "PLEASE FIND A HOSPITAL") {
            toast.error("¡URGENCIA MUY ELEVADA! Inmediatamente Hospital");
            setLevel0(true);
            setEmergency(true);
        } else {
            setLevel0(false);
            toast.success("El nivel del triaje es manejable")
            setCurrentStep(3)
            setEmergency(false);
        }
    }

    // Formulario de Localización
    const [hotelValue, setHotelValue] = useState<Option | null>(null);

    const hotelOptions = hotelOptionsData
        .sort((a: any, b: any) => a.name.localeCompare(b.name))
        .map((item: any) => ({
            value: item.id.toString(),
            label: item.name
        }));

    const handleHotelChange = (selectedOption: Option | null) => {
        setHotelValue(selectedOption);
    };

    // -------------- PARA EL INPUT HABITACIÓN  --------------
    const [roomValue, setRoomValue] = useState("");
    // color blue for circle: #3067ff

    // ------------ PARA EL FORMULARIO DEL PACIENTE  ------------
    const [userData, setUserData] = useState({
        name: '',
        surname: '',
        email: '',
        emailconfirm: '',
        phone: '',
        idcard: '',
        birthday: '',
        genre: '',
        policynum: '',
        group: '0',
        source: '',
        insurance: ''
    });

    const [errors, setErrors] = useState({
        name: '',
        surname: '',
        email: '',
        emailconfirm: '',
        document: '',
        idcard: '',
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setUserData(prevUserData => ({
            ...prevUserData,
            [id]: value
        }));
        validateInput(id, value);
    };

    const validateInput = (id: string, value: string) => {
        let error = '';
        const fullnameRegex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        switch (id) {
            case 'name':
                error = !fullnameRegex.test(value) ? 'Nombre no es válido' : '';
                break;
            case 'surname':
                error = !fullnameRegex.test(value) ? 'Este apellido no es válido' : '';
                break;
            case 'email':
                error = !emailRegex.test(value) ? 'Correo electrónico no es válido' : '';
                break;
            case 'emailconfirm':
                error = value !== userData.email ? 'Correos no coinciden' : '';
                break;
            case 'idcard':
                if (selectedDocument === 'D' && !/^\d{8}[A-Za-z]$/.test(value)) {
                    error = 'DNI no válido';
                } else if (selectedDocument === 'N' && !/^[XYZ]\d{7,8}[A-Za-z]$/.test(value)) {
                    error = 'NIE no válido';
                } else if (selectedDocument === 'P' && !/^[A-Za-z0-9]{6,9}$/.test(value)) {
                    error = 'Pasaporte no válido';
                } else {
                    error = '';
                }
                break;
            default:
                break;
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            [id]: error
        }));
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!isStepFilled(1) || !isStepFilled(2) || !isStepFilled(3)) {
            toast.error('Rellene los apartados con signos de interrogación');
            return;
        }
        if (userData.email !== userData.emailconfirm) {
            toast.error("Los correos no coinciden");
            return;
        }

        if (userExist) {
            await linkTriageUser();
        } else {
            await registerUserWithTriage();
        }
    };

    const linkTriageUser = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No hay token guardado.');
            return;
        }
        const linkTriageUser = {
            identificator: userData.source === "Seguro" && userData.insurance === "MCI" ? "+" + userData.phone : userData.idcard,
            systemRes: firstValue ? parseInt(firstValue.value) : null,
            symptomRes: secondValue ? parseInt(secondValue.value) : null,
            asympRes: `[${thirdValue?.map(item => item.value).join(', ')}]`, // Para que tenga este formato "[num]"
            hotelRes: hotelValue ? parseInt(hotelValue.value) : null,
            roomRes: roomValue
        };

        const response = await fetch(`${apiUrl}/callcenter/linkTriageUser`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(linkTriageUser)
        });

        const result = await response.text();
        if (response.ok) {
            setIdentificator(result);
            setDone(true);
            return response;
        }
        console.log(response)
        if (!response.ok && response.status === 422) {
            // const errorMessage = await response.text();
            // if (errorMessage === "El usuario ya tiene un triage pendiente") {
            toast.error('El usuario ya tiene un triage pendiente');
            return;
            // }
        } else {
            toast.error('No se ha podido vincular el triaje con el usuario');
            return;

        }
    };

    const registerUserWithTriage = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No hay token guardado.');
            return;
        }
        const userwithTriage = {
            email: userData.email ? userData.email : null,
            name: userData.name,
            surname: userData.surname,
            idcard: userData.idcard ? `${selectedDocument}-${userData.idcard}` : null,
            phone: "+" + userData.phone,
            genre: userData.genre ? userData.genre : null,
            birthday: userData.birthday,
            systemRes: firstValue ? parseInt(firstValue.value) : null,
            symptomRes: secondValue ? parseInt(secondValue.value) : null,
            asympRes: `[${thirdValue?.map(item => item.value).join(', ')}]`, // Para que tenga este formato "[num]"
            hotelRes: hotelValue ? parseInt(hotelValue.value) : null,
            roomRes: roomValue,
            policyNumber: userData.policynum ? userData.policynum : null,
            group: userData.group ? parseInt(userData.group) : 0,
            language: language && userData.source === 'Hotel' ? language : null,
            companyId: userData.insurance === "MCI" ? 8 : userData.insurance === "MRI" ? 9 : userData.source === "Drnear" ? 10 : userData.source === "Quirón" ? 7 : null
        };

        const response = await fetch(`${apiUrl}/callcenter/userwithtriage`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(userwithTriage)
        });

        const identificator = await response.text();
        if (response.ok) {
            setIdentificator(identificator);
            setDone(true);
            return;
        }

        if (!response.ok && response.status === 422) {
            // const errorMessage = await response.text();
            // if (errorMessage === "El usuario ya tiene un triage pendiente") {
            toast.error('El usuario ya tiene un triage pendiente');
            return;
            // }
        } else if (response.status === 409) {
            setUserExist(true);
            const linkTriageUserResponse = await linkTriageUser();
            if (linkTriageUserResponse?.status === 201) {
                toast.success("El usuario ya existe, el triaje se vinculó a la cuenta.");
                return;
            }
            return;
        } else {
            toast.error('No se ha podido registrar el usuario y triaje.');
            return;
        }
    };

    // ------ Evitar que react-select se expanda a medida que se añaden opciones ------
    const customStyles = {
        container: (css: any) => ({ ...css, maxWidth: '400px' }),
        valueContainer: (css: any) => ({ ...css, "flexWrap": "nowrap", "whiteSpace": "nowrap", overflow: "hidden", 'textOverflow': 'ellipsis' })
    };

    const handleValidate = async (e: any) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No hay token guardado.');
            return;
        }
        const ipakyou = isPhoneInput ? userData.idcard : `${selectedDocument}-${userData.idcard}`;
        const response = await fetch(`${apiUrl}/callcenter/validateUser/${ipakyou}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            },
        });

        const result = await response.json();

        if (result) {
            setValidUser(true);
            return;
        } else {
            setValidUser(false);
            toast.error('El usuario no existe en nuestro sistema.');
            return;
        }
    }

    const documentTypes = [
        { value: '[N]', label: 'NIE' },
        { value: '[D]', label: 'DNI' },
        { value: '[P]', label: 'PAS' },
    ];

    // const handleZoneValidation = () => {
    //     if (!hotelValue) {
    //         toast.error('Rellena el campo de hotel');
    //         return;
    //     }
    //     if (!roomValue) {
    //         toast.error('Rellena el campo de habitación');
    //         return;
    //     }

    //     if (hotelValue && roomValue) {
    //         setCurrentStep(2);
    //     }

    // };

    const resetValues = () => {
        setFirstValue(null);
        setSecondValue(null);
        setThirdValue([]);
        setHotelValue(null);
        setRoomValue('');
        setCurrentStep(1);
        setQuestion(true);
        setUserExist(null);
        setLevel0(null);
        setEmergency(null);
        setLanguage('');
        setUserData({
            name: '',
            surname: '',
            email: '',
            emailconfirm: '',
            phone: '',
            idcard: '',
            birthday: '',
            genre: '',
            policynum: '',
            group: '0',
            source: '',
            insurance: ''
        });
    };

    useEffect(() => {
        const handlePopStateChange = (event: PopStateEvent) => {
            if (firstValue || secondValue || thirdValue || roomValue || hotelValue || userData) {
                if (!window.confirm("Tienes cambios sin guardar, ¿realmente quieres dejarlo?")) {
                    event.preventDefault();
                    window.history.pushState(null, '', window.location.href);
                }
            }
        };

        window.addEventListener('popstate', handlePopStateChange);

        return () => {
            window.removeEventListener('popstate', handlePopStateChange);
        };
    }, [firstValue, secondValue, thirdValue, roomValue, hotelValue, userData]);

    const isStepFilled = (step: number) => {
        switch (step) {
            case 1:
                return hotelValue !== null && roomValue !== '';
            case 2:
                return firstValue !== null && secondValue !== null && thirdValue !== null && level0 === false;
            case 3:
                if (validUser) {
                    return userData.idcard !== '' && userExist === true && validUser === true;
                } else {
                    if (userExist === false) {
                        if (userData.source === 'Seguro') {
                            return (
                                userData.name !== '' &&
                                userData.surname !== '' &&
                                userData.phone !== '' &&
                                userData.birthday !== '' &&
                                // userData.genre !== '' &&
                                userData.group !== '0' &&
                                userData.insurance !== ''
                            );
                        } else if (userData.source === 'Hotel' || userData.source === 'Drnear' || userData.source === 'Quirón') {
                            return (
                                userData.email !== '' &&
                                userData.emailconfirm !== '' &&
                                userData.name !== '' &&
                                userData.surname !== '' &&
                                userData.phone !== '' &&
                                userData.idcard !== '' &&
                                userData.birthday !== '' &&
                                userData.genre !== '' &&
                                userData.group !== '' &&
                                language !== ''
                            );
                        }
                    }
                }
                return false;

            default:
                return false;
        }
    };

    const checkUrgency = (step: number) => {
        if (step !== 2 && emergency === null && (firstValue !== null && secondValue !== null && thirdValue !== null && level0 === null)) {
            handleUrgency()
        }
        setCurrentStep(step)
    }

    return (
        <div className='triage-container'>
            {firstLoading && <Loading />}
            <div className='triage-header'>
                <h3>Pasos <HiOutlineQuestionMarkCircle className='questionark-circle' onClick={() => setInstruction(true)} /></h3>
                <hr />
                <div className='triage-steps'>
                    {[1, 2, 3, 4].map(step => (
                        <div key={step}>
                            {step === 4 ? (
                                <p
                                    onClick={isStepFilled(1) && isStepFilled(2) && isStepFilled(3) ? handleSubmit : undefined}
                                    style={{
                                        backgroundColor: (isStepFilled(1) && isStepFilled(2) && isStepFilled(3)) ? '#00CDAD' : '#a2aab1',
                                        pointerEvents: (isStepFilled(1) && isStepFilled(2) && isStepFilled(3)) ? 'auto' : 'none'
                                    }}
                                >
                                    <IoCheckmarkDone className='check-done' />
                                </p>
                            ) : (
                                <p onClick={() => checkUrgency(step)} style={{ backgroundColor: currentStep === step ? '#20A2A0' : (isStepFilled(step) ? '#659D17' : '#a2aab1') }}>
                                    {String.fromCharCode(64 + step)}
                                    <span className='exponent-icon'>{isStepFilled(step) ? <MdDoneOutline style={{ color: 'yellowgreen' }} /> : <MdQuestionMark style={{ color: 'peru' }} />}</span>
                                </p>
                            )}
                            <span>{step === 1 ? 'Localización' : step === 2 ? 'Triaje' : step === 3 ? 'Datos' : 'Listo'}</span>
                        </div>
                    ))}
                </div>
            </div>

            <div className='triage-body'>
                {currentStep === 1 && (
                    <>
                        <div className='triage-column-two'>
                            <label htmlFor="">Hotel </label>
                            <p>De las opciones disponibles, seleccione el hotel en el que se hospeda el paciente. Puede utilizar el buscador</p>
                            <Select
                                value={hotelValue}
                                onChange={handleHotelChange}
                                options={hotelOptions}
                                placeholder="Selecciona el hotel del paciente..."
                                isSearchable={true}
                                isClearable={true}
                                menuIsOpen={true}
                            />
                        </div>

                        <div className='triage-column-two'>
                            <label htmlFor="">Habitación</label>
                            <p>Introduzca la habitación en la que se aloja el paciente dentro del hotel seleccionado</p>
                            <input className='input-room' type="text" placeholder='Introduce el piso o el número de habitación' value={roomValue} onChange={(e: any) => setRoomValue(e.target.value)} />
                        </div>

                        <button onClick={() => setCurrentStep(2)}
                            className='send-location'>
                            Siguiente
                        </button>
                    </>
                )}

                {currentStep === 2 && (
                    <>
                        <div className='triage-column'>
                            <label htmlFor="">Categorías principales</label>
                            <p>Clasifica el problema del paciente en una de las siguientes categorías como fiebre y malestar, dolor, problemas respiratorios, entre otros.</p>
                            <Select
                                value={firstValue}
                                onChange={handleFirstChange}
                                options={firstOptions}
                                placeholder="Selecciona la categoria del problema..."
                                isSearchable={true}
                                isClearable={true}
                                menuIsOpen={true}
                            />
                        </div>

                        <div className='triage-column'>
                            <label htmlFor="">Problemas especificos </label>
                            <p>Determina qué sistema del cuerpo está afectado por el problema categorizado, como el sistema digestivo, respiratorio, cardiovascular, etc.</p>
                            <Select
                                value={secondValue}
                                onChange={handleSecondChange}
                                options={secondOptions}
                                placeholder="Selecciona el problema especifico..."
                                isSearchable={true}
                                isClearable={true}
                                menuIsOpen={true}
                            />
                        </div>

                        <div className='triage-column'>
                            <label htmlFor="">Síntomas asociados</label>
                            <p>Identifica y selecciona todos los síntomas específicos que el paciente está experimentando. <strong> Selección múltiple</strong></p>
                            <Select
                                value={thirdValue}
                                onChange={handleThirdChange}
                                options={thirdOptions}
                                placeholder="Selecciona los sintomas actuales..."
                                isSearchable={true}
                                isClearable={true}
                                isMulti
                                menuIsOpen={true}
                                styles={customStyles}
                            />
                        </div>

                        <button onClick={() => {
                            if (firstValue !== null && secondValue !== null && thirdValue !== null) {
                                setBackButton(true);
                                handleUrgency();
                            } else {
                                setCurrentStep(1);
                            }
                        }}
                            className='cancel-location'>Volver
                        </button>
                        {/* {(thirdValue && thirdValue.length > 0) && ( */}
                        <button onClick={handleUrgency} className='send-triage'>Siguiente</button>
                        {/* )} */}
                    </>
                )}

                {currentStep === 3 && (
                    <>
                        {question && (
                            <div className='before-userdata'>
                                <h3>¿El usuario ya está registrado en DR2U?</h3>
                                <div className='before-buttons'>
                                    <button onClick={() => { setUserExist(true); setQuestion(false) }}>Si</button>
                                    <button onClick={() => { setUserExist(false); setQuestion(false) }}>No</button>
                                </div>
                            </div>
                        )}
                        {(userExist === false) && (
                            <div className="patient-container">

                                <div className="form-title">
                                    <IoArrowBackOutline style={{ height: '24px', width: '24px', cursor: 'pointer' }} onClick={() => { setUserExist(null); setQuestion(true) }} />
                                    <p>Crear paciente</p>
                                </div>
                                <form action="#" onSubmit={handleSubmit} >

                                    <div className='insurance-title-top'>
                                        <p>Procedencia del paciente</p>
                                        <div className='insurance-radio'>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="source"
                                                    value="Seguro"
                                                    checked={userData.source === "Seguro"}
                                                    onChange={(e: any) => setUserData({ ...userData, source: e.target.value })}
                                                />
                                                <span className="dot"></span>
                                                Seguro
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="source"
                                                    value="Hotel"
                                                    checked={userData.source === "Hotel"}
                                                    onChange={(e: any) => setUserData({ ...userData, source: e.target.value })}
                                                />
                                                <span className="dot"></span>
                                                Hotel
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="source"
                                                    value="Drnear"
                                                    checked={userData.source === "Drnear"}
                                                    onChange={(e: any) => setUserData({ ...userData, source: e.target.value })}
                                                />
                                                <span className="dot"></span>
                                                Drnear.me
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="source"
                                                    value="Quirón"
                                                    checked={userData.source === "Quirón"}
                                                    onChange={(e: any) => setUserData({ ...userData, source: e.target.value })}
                                                />
                                                <span className="dot"></span>
                                                Quirón
                                            </label>
                                        </div>
                                        {userData.source === "Seguro" && (
                                            <div className='insurance-select'>
                                                <label htmlFor="insuranceType">Compañía de seguro:</label>
                                                <select
                                                    id="insuranceType"
                                                    value={userData.insurance || ""}
                                                    onChange={(e: any) => setUserData({ ...userData, insurance: e.target.value })}
                                                >
                                                    <option value="" disabled>Seleccione</option>
                                                    <option value="MCI">MCI</option>
                                                    <option value="MRI">MRI</option>
                                                </select>
                                            </div>

                                        )}
                                    </div>

                                    {userData.source !== '' && //<-----savepoint reference
                                        <>
                                            {userData.source === 'Seguro' && userData.insurance === "MCI" ? (
                                                <div className="user_details">
                                                    <div className="input_box">
                                                        <label htmlFor="name">Nombre</label>
                                                        <div className='fullname-group'>
                                                            <input
                                                                className='userinput-name'
                                                                type="text"
                                                                id="name"
                                                                placeholder="Nombre"
                                                                required
                                                                value={userData.name}
                                                                onChange={handleInputChange}
                                                            />

                                                        </div>
                                                        {errors.name && <span className="error">{errors.name}</span>}
                                                    </div>
                                                    <div className="input_box">
                                                        <label htmlFor="surname">Apellidos</label>
                                                        <input
                                                            className='userinput-surname'
                                                            type="text"
                                                            id="surname"
                                                            placeholder="Apellidos"
                                                            required
                                                            value={userData.surname}
                                                            onChange={handleInputChange}
                                                        />
                                                        {errors.surname && <span className="error">{errors.surname}</span>}
                                                    </div>
                                                    <div className="input_box">
                                                        <label htmlFor="phone">Número de teléfono</label>
                                                        <PhoneInput
                                                            buttonStyle={{
                                                                border: "none",
                                                            }}
                                                            inputStyle={{
                                                                width: "100%",
                                                                height: "45px",
                                                                border: "none",
                                                                outline: "none",
                                                                backgroundColor: "#f6f8fa",
                                                                fontSize: "16px",
                                                                borderRadius: "5px",
                                                                transition: "all 120ms ease-out 0s",
                                                                boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)"
                                                            }}
                                                            localization={spanish}
                                                            regions={'europe'}
                                                            country={'es'}
                                                            value={userData.phone}
                                                            onChange={(phone) => setUserData({ ...userData, phone: phone })}
                                                        />
                                                    </div>
                                                    <div className="input_box">
                                                        <label htmlFor="birth">Fecha de nacimiento</label>
                                                        <DatePicker
                                                            selected={userData.birthday ? new Date(userData.birthday) : null}
                                                            onChange={(date: Date | null) => setUserData({ ...userData, birthday: date ? date.toISOString() : '' })}
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="dd/mm/aaaa"
                                                            isClearable
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            scrollableYearDropdown
                                                            yearDropdownItemNumber={100}
                                                            maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 16))}
                                                            popperPlacement="top-start"
                                                            locale="es"
                                                            className='datepickerbirthday'

                                                        />
                                                    </div>
                                                </div>
                                            ) : userData.insurance === "" && userData.source === "Seguro" ? null : (
                                                <div className="user_details">
                                                    <div className="input_box">
                                                        <label htmlFor="name">Nombre y Apellido</label>
                                                        <div className='fullname-group'>
                                                            <input
                                                                className='userinput-name'
                                                                type="text"
                                                                id="name"
                                                                placeholder="Nombre"
                                                                required
                                                                value={userData.name}
                                                                onChange={handleInputChange}
                                                            />
                                                            <input
                                                                className='userinput-surname'
                                                                type="text"
                                                                id="surname"
                                                                placeholder="Apellidos"
                                                                required
                                                                value={userData.surname}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        {errors.name && <span className="error">{errors.name}&emsp;</span>}
                                                        {errors.surname && <span className="error">{errors.surname}</span>}
                                                    </div>
                                                    <div className="input_box">
                                                        <label htmlFor="document">Documento de identidad</label>
                                                        <div className="document_input_container">
                                                            <select id="document-select" value={selectedDocument} onChange={(e: any) => setSelectedDocument(e.target.value)}>
                                                                <option value="" disabled>Tipo</option>
                                                                {documentTypes.map((doc) => (
                                                                    <option key={doc.value} value={doc.value}>
                                                                        {doc.label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <input
                                                                type="text"
                                                                id="documentid"
                                                                placeholder="DNI/PASAPORTE"
                                                                required
                                                                value={userData.idcard}
                                                                onChange={(e: any) => setUserData({ ...userData, idcard: e.target.value })}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="input_box">
                                                        <label htmlFor="email">Correo electrónico</label>
                                                        <input
                                                            type="email"
                                                            id="email"
                                                            placeholder="ejemplo@dominio.com"
                                                            required
                                                            value={userData.email}
                                                            onChange={handleInputChange}
                                                        />
                                                        {errors.email && <span className="error">{errors.email}</span>}
                                                    </div>
                                                    <div className="input_box">
                                                        <label htmlFor="email">Confirmar correo</label>
                                                        <input
                                                            type="email"
                                                            id="emailconfirm"
                                                            placeholder="ejemplo@dominio.com"
                                                            required
                                                            value={userData.emailconfirm}
                                                            onChange={handleInputChange}
                                                        />
                                                        {errors.emailconfirm && <span className="error">{errors.emailconfirm}</span>}
                                                    </div>
                                                    <div className="input_box">
                                                        <label htmlFor="phone">Número de teléfono</label>
                                                        <PhoneInput
                                                            buttonStyle={{
                                                                border: "none",
                                                            }}
                                                            inputStyle={{
                                                                width: "100%",
                                                                height: "45px",
                                                                border: "none",
                                                                outline: "none",
                                                                backgroundColor: "#f6f8fa",
                                                                fontSize: "16px",
                                                                borderRadius: "5px",
                                                                transition: "all 120ms ease-out 0s",
                                                                boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)"
                                                            }}
                                                            localization={spanish}
                                                            regions={'europe'}
                                                            country={'es'}
                                                            value={userData.phone}
                                                            onChange={(phone) => setUserData({ ...userData, phone: phone })}
                                                        />
                                                    </div>
                                                    <div className="input_box">
                                                        <div className='language_container'>
                                                            <div>
                                                                <label htmlFor="birth">Fecha de nacimiento</label>
                                                                <DatePicker
                                                                    selected={userData.birthday ? new Date(userData.birthday) : null}
                                                                    onChange={(date: Date | null) => setUserData({ ...userData, birthday: date ? date.toISOString() : '' })}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="dd/mm/aaaa"
                                                                    isClearable
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    scrollableYearDropdown
                                                                    yearDropdownItemNumber={100}
                                                                    maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 16))}
                                                                    popperPlacement="top-start"
                                                                    locale="es"
                                                                    className='datepickerbirthday'

                                                                />
                                                            </div>
                                                            <div style={{ flex: 1 }}>
                                                                <label htmlFor="language">Lengua materna</label>
                                                                <select style={{ width: '100%' }} id="document-select" value={language} onChange={(e: any) => setLanguage(e.target.value)}>
                                                                    {LANGUAGES.map((doc) => (
                                                                        <option key={doc.value} value={doc.value}>
                                                                            {doc.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            )}
                                            <div className='triage-bottom-options'>

                                                {(userData.source === "Hotel" || userData.source === "Drnear" || userData.source === "Quirón") &&
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <div className="gender">
                                                            <span className="gender_title">Género</span>
                                                            <input type="radio" name="gender" id="radio_1" value="Male" checked={userData.genre === 'Male'} onChange={(e: any) => setUserData({ ...userData, genre: e.target.value })} />
                                                            <input type="radio" name="gender" id="radio_2" value="Female" checked={userData.genre === 'Female'} onChange={(e: any) => setUserData({ ...userData, genre: e.target.value })} />
                                                            <input type="radio" name="gender" id="radio_3" value="Other" checked={userData.genre === 'Other'} onChange={(e: any) => setUserData({ ...userData, genre: e.target.value })} />
                                                            <div className="category">
                                                                <label htmlFor="radio_1">
                                                                    <span className="dot one" />
                                                                    <span>Masculino</span>
                                                                </label>
                                                                <label htmlFor="radio_2">
                                                                    <span className="dot two" />
                                                                    <span>Femenino</span>
                                                                </label>
                                                                <label htmlFor="radio_3">
                                                                    <span className="dot three" />
                                                                    <span>Otro</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="reg_btn">
                                                            <input type="submit" defaultValue="Register" />
                                                        </div>
                                                    </div>
                                                }

                                                {userData.source === 'Seguro' && userData.insurance !== '' && (
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <div className='insurance-title'>
                                                            <p>Tipo de cobertura</p>
                                                            <div className='insurance-radio'>
                                                                <label>
                                                                    <input
                                                                        type="radio"
                                                                        name="insured2"
                                                                        value="3"
                                                                        checked={userData.group === "3"}
                                                                        onChange={(e) => setUserData({ ...userData, group: e.target.value })}
                                                                    />
                                                                    <span className="dot"></span>
                                                                    Cobertura total
                                                                </label>
                                                                <label>
                                                                    <input
                                                                        type="radio"
                                                                        name="insured2"
                                                                        value="2"
                                                                        checked={userData.group === "2"}
                                                                        onChange={(e) => setUserData({ ...userData, group: e.target.value })}
                                                                    />
                                                                    <span className="dot"></span>
                                                                    Copago
                                                                </label>

                                                            </div>
                                                        </div>
                                                        <div className="reg_btn">
                                                            <input type="submit" defaultValue="Register" />
                                                        </div>
                                                    </div>
                                                )}

                                            </div>

                                        </>
                                    }

                                    {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                        <div className="reg_btn">
                                            <input type="submit" defaultValue="Register" />
                                        </div>
                                    </div> */}
                                </form>
                            </div>
                        )}
                        {(userExist === true) && (
                            <div className="patient-container">
                                <div className="form-title">
                                    <p>Usuario registrado</p>
                                </div>
                                <br />
                                {(validUser === null) && (
                                    <div className='formform'>
                                        <form action="#" onSubmit={handleValidate}>

                                            <div className='verify-toggle-input-type'>
                                                <button type="button" onClick={() => setIsPhoneInput(!isPhoneInput)}>
                                                    {isPhoneInput ? "Usar Documento de Identidad" : "Usar Teléfono"}
                                                </button>
                                            </div>

                                            <div className="input_box">
                                                {!isPhoneInput ? (
                                                    <label htmlFor="document">Documento de identidad</label>
                                                ) : (
                                                    <label htmlFor="document">Número de teléfono</label>
                                                )}
                                                <div className="document_input_container">
                                                    {/* Conditionally render either the document or phone input */}
                                                    {!isPhoneInput ? (
                                                        <>
                                                            <select value={selectedDocument} onChange={(e: any) => setSelectedDocument(e.target.value)}>
                                                                {documentTypes.map((doc) => (
                                                                    <option key={doc.value} value={doc.value}>
                                                                        {doc.label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <input
                                                                type="text"
                                                                id="documentid"
                                                                placeholder="NIE/PASAPORTE"
                                                                required
                                                                value={userData.idcard}
                                                                onChange={(e: any) => setUserData({ ...userData, idcard: e.target.value })}
                                                            />
                                                        </>
                                                    ) : (
                                                        <PhoneInput
                                                            buttonStyle={{
                                                                border: "none",
                                                            }}
                                                            inputStyle={{
                                                                width: "100%",
                                                                height: "45px",
                                                                border: "none",
                                                                outline: "none",
                                                                backgroundColor: "#f6f8fa",
                                                                fontSize: "16px",
                                                                borderRadius: "5px",
                                                                transition: "all 120ms ease-out 0s",
                                                                boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.1)"
                                                            }}
                                                            localization={spanish}
                                                            regions={'europe'}
                                                            country={'es'}
                                                            value={userData.idcard}
                                                            onChange={(phone) => setUserData({ ...userData, idcard: `+${phone}` })}
                                                        />
                                                    )}
                                                </div>
                                            </div>



                                            <div className='userform-button'>
                                                <button className='gobacknow' onClick={() => { setUserExist(null); setQuestion(true); }}>Volver</button>
                                                <div className="reg_btn">
                                                    <input type="submit" defaultValue="Register" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                )}
                                {(validUser === true) && (
                                    <div className='verification-step'>
                                        <BsShieldExclamation className='security' />
                                        <h3>Acabamos de verificar que el usuario <u>existe</u>, si desea realizar triaje para este usuario considere presionar el botón, si no, vuelva atrás</h3>
                                        <div className='verification-buttons'>
                                            <button className="linkbutton" onClick={handleSubmit}>Vincular</button>
                                            <button className="gobacklink" onClick={() => { setValidUser(null) }}>Volver</button>
                                        </div>
                                    </div>
                                )}
                                {(validUser === false) && (
                                    <div className='verification-step'>
                                        <BsShieldExclamation className='security' />
                                        <h3>Acabamos de comprobar que el usuario <u>no existe</u>, por favor, rellene el formulario de usuario o introduzca de nuevo la ID</h3>
                                        <div className='verification-buttons'>
                                            <button className='verification-result' onClick={() => { setUserExist(false); setQuestion(false) }}>Formulario</button>
                                            <button className='verification-result' onClick={() => { setValidUser(null) }}>Volver</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                    </>
                )}

            </div>

            {emergency && (
                <Derive
                    editModal={emergency}
                    setEditModal={setEmergency}
                    resetValues={resetValues}
                    levelzero={level0}
                    setLevelzero={setLevel0}
                    setCurrentStep={setCurrentStep}
                    backButton={backButton} />
            )}

            {done && (
                <SuccessTriage
                    editModal={done}
                    setEditModal={setDone}
                    identificator={identificator}
                    resetValues={resetValues} />
            )}

            {instruction && (
                <ShowInstruction
                    editModal={instruction}
                    setEditModal={setInstruction} />
            )}

            {/* {firstValue && (
                <div>
                    <p>Sistema seleccionado: {firstValue.value}</p>
                    {secondValue && <p>Sintoma seleccionado: {secondValue.value}</p>}
                    {thirdValue && thirdValue.length > 0 && (
                        <p>Asintoma seleccionado: {thirdValue.map((item: Option) => item.value).join(', ')}</p>
                    )}
                </div>
            )} */}

        </div>
    );
};

export default Triage;
