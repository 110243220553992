// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #fff;
  color: #20A2A0;
}

.grid-container {
  display: grid;
  grid-template-columns: 250px 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas: "sidebar header header header" "sidebar main main main";
  height: 100vh;
  transition: grid-template-columns 0.5s;
}

.grid-container.sidebar-closed {
  grid-template-columns: 0 1fr 1fr 1fr;
}

.grid-container h2 {
  padding-left: 1.25rem;
}

@media screen and (max-width: 992px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas: "header" "main";
  }
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,cAAA;AACJ;;AAEA;EACI,aAAA;EACA,wCAAA;EACA,6BAAA;EACA,4EACI;EAEJ,aAAA;EACA,sCAAA;AADJ;;AAIA;EACI,oCAAA;AADJ;;AAIA;EACI,qBAAA;AADJ;;AAIA;EACI;IACI,0BAAA;IACA,6BAAA;IACA,oCACI;EAFV;AACF","sourcesContent":["body {\n    background-color: #fff;\n    color: #20A2A0;\n}\n\n.grid-container {\n    display: grid;\n    grid-template-columns: 250px 1fr 1fr 1fr;\n    grid-template-rows: 0.2fr 3fr;\n    grid-template-areas:\n        'sidebar header header header'\n        'sidebar main main main';\n    height: 100vh;\n    transition: grid-template-columns 0.5s;\n}\n\n.grid-container.sidebar-closed {\n    grid-template-columns: 0 1fr 1fr 1fr;\n}\n\n.grid-container h2 {\n    padding-left: 1.25rem;\n}\n\n@media screen and (max-width: 992px) {\n    .grid-container {\n        grid-template-columns: 1fr;\n        grid-template-rows: 0.2fr 3fr;\n        grid-template-areas:\n            'header'\n            'main';\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
